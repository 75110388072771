import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { CambioPasswordFacadeService } from './cambio-password-facade.service';
import * as sha1 from 'sha1';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cambio-password',
  templateUrl: './cambio-password.component.html',
  styleUrls: ['./cambio-password.component.css']
})
export class CambioPasswordComponent implements OnInit, OnDestroy {

  public idTipoAccion: number;
  public claveForm: FormGroup;
  public dataUsuario: any;

  public suscripciones: Subscription = new Subscription();
  public hide1 = true;
  public hide2 = true;
  public hide3 = true;




  constructor(private toastr:ToastrService, public cambioPasswordFacade: CambioPasswordFacadeService, public activatedRoute: ActivatedRoute, private _toast: ToastrServiceLocal, public router:Router) {
    this.claveForm = new FormGroup({
      passActual: new FormControl('', [Validators.required]),
      repetirPass: new FormControl('', [Validators.required]),
      passNuevo: new FormControl('', [Validators.required]),
      usuario: new FormControl('')
    });

   
    this.obtenerDataLocal();
  }

  ngOnInit(): void {

    this.idTipoAccion = Number(this.activatedRoute.snapshot.paramMap.get('idTipoAccion'));
   
  }

  ngOnDestroy(): void {
    this.suscripciones.unsubscribe();
  }

  obtenerDataLocal() {
    this.dataUsuario = JSON.parse(localStorage.getItem("data_go504"));
    console.log(this.dataUsuario);
    if (!this.dataUsuario) {
      this.router.navigateByUrl('/login');
    }
  }

  validarFormularioPass() {
    if (this.claveForm.invalid) {
      this.claveForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'Es necesario completar los campos requeridos');
      return false;
    } 
    else if (this.claveForm.value.repetirPass != this.claveForm.value.passNuevo) {
      this.claveForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'Las contraseñas ingresadas no coinciden');
      return false
    }
    else if (this.claveForm.value.passActual === this.claveForm.value.passNuevo) {
      this.claveForm.markAllAsTouched();
      this._toast.mensajeWarning('', 'La nueva contraseña debe de ser distinta a la anterior');
      return false
    }
    
    else {
      return true;
    }
  }

  

  cambiarPassword() {
    if (this.validarFormularioPass() === false) {
      return;
    }

    this.cambioPasswordFacade.cambioClave({
      // usuario: this.dataUsuario.usuario,
      valor: [
        {
          passActual: sha1(this.claveForm.value.passActual),
          passNuevo: sha1(this.claveForm.value.passNuevo),
          usuario: this.dataUsuario.usuario
        }
      ]
    });

    this.suscripciones.add(
      this.cambioPasswordFacade.responseAction$.subscribe((result) => {
        if (result.hasError === false) {
        this.toastr.info(result.data.table0[0].descripcion);
          // if (this.idTipoAccion === 1 &&Number(localStorage.getItem("site")) === 1) {
          //   console.log('entro');
          //   this.router.navigateByUrl('/pinSeguridad/1');
          //   return;
          // } 
          // if (this.idTipoAccion === 1 && String(localStorage.getItem("site")) === "2") {
          //   this.router.navigateByUrl('/template/afiliados/mi-perfil');
          //   return;
          // } 
          // else {
            this.router.navigateByUrl('/mosaicMenu');
            return;

          //}
        }
      })
    );
    
  }

  navegar() {
     if (localStorage.getItem(environment.apiUrlSecurity)) {
       this.router.navigateByUrl('/mosaicMenu');
      } else {
       localStorage.clear();
       this.router.navigateByUrl('/login');
     }
  }

  volver(){
    this.router.navigateByUrl('/mosaicMenu');

  }
  


}
