import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataApi } from '../Model/dataApi';
import { MensajesHttpService } from '../utils/mensajesHttp/mensajes-http.service';
import { SeguridadService } from './seguridad/seguridad.service';
import { ToastrServiceLocal } from './toast/toastr.service';
import  menus1 from './Menus.json';
import  modulo1 from './modulos.json';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  constructor( public router: Router, public seguridadService: SeguridadService, public _toast: ToastrServiceLocal, 
    public _mensajesHttp: MensajesHttpService) { }

   
      
  CerrarSesion(){
    let site = localStorage.getItem("site");
    localStorage.clear();
    if(site === "1"){
      this.router.navigateByUrl('login');
    }else{
      this.router.navigateByUrl('public-pages/files/login');

    }
  }

modulos:any=[]
  Login(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', '');

    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/consulta/${environment.processEnv}/v1/auth/login/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        // console.log(result)
        localStorage.setItem("token_go504", result.token);
        localStorage.setItem("data_go504", JSON.stringify(result.data));
        localStorage.setItem("menusTotales", JSON.stringify(menus1));
        // cambiar si cambia el rol de admin
        // console.log(result.data.idRol)
        if (result.data.idRol!=1) {
        this.modulos= modulo1.filter(item=>{
          return item.idModule == 500
          
         })
        // console.log(this.modulos)
        }else
        {
        this.modulos= modulo1
        // console.log(this.modulos)

        }
        result.data.idRol!=1?localStorage.setItem("rol", '152RFRT698FF9D9S'):localStorage.setItem("rol", '8C7FDBBE-DY4E-40L0-83A1-8AUI56DE9522')
       localStorage.setItem("modulos", JSON.stringify(this.modulos))
        localStorage.setItem("site", "1");



        if (result.data.cambioContrasena === true) {
          this.router.navigateByUrl('/cambioPassword/1');
        } else {
          this.router.navigateByUrl('/mosaicMenu');
        }
        // this._toast.mensajeSuccess('Bienvenido', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al entrar al sistema', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  LoginPublic(params: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', '');

    const request$ = this.seguridadService.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/auth/login/`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        localStorage.setItem("token_go504", result.token);
        localStorage.setItem("data_go504", JSON.stringify(result.data));
        localStorage.setItem("menusTotales", JSON.stringify(result?.menus));
        localStorage.setItem("modulos", result?.data?.modulo);
        localStorage.setItem("site", "2");

        if (result.data.cambioContrasena === true) {
          this.router.navigateByUrl('/cambioPassword/1');
        } else {
          this.router.navigateByUrl('template/afiliados/mi-perfil');
        }
        // this._toast.mensajeSuccess('Bienvenido', '');
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._toast.clearToasts();
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al entrar al sistema', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  obtenerMenus(){
    return JSON.parse(localStorage.getItem("menusTotales"));
  }
  

}
