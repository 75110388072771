import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { menuInterface } from 'src/app/Model/Seguridad/menu.Interface';

@Injectable({
  providedIn: 'root'
})
export class NewSidemeduFacadeService {

  public menus: any[] = [
    {
      idMenu: 12,
      descripcion: "Modulo Solicitudes",
      texto: "Solicitudes",
      idPadre: 0,
      icono: "fas fa-shopping-cart",
      url: "",
      visible: true,
      activo: true,
      subItems: true,
      idModulo: 1003,
      hijos: [
        {
          idMenu: 17,
          idPadre: 12,
          texto: "Registro Pago",
          descripcion: "Registro Pago",
          icono: "fas fa-cash-register",
          url: "afiliados/registrar-pago",
          visible: true,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 13,
          idPadre: 12,
          texto: "Solicitud",
          descripcion:"",
          icono: "fas fa-shopping-cart",
          url: "afiliados/solicitudes",
          visible: true,
          activo: true,
          subItems: true,
        }
        
       
      ],
    },
    {
      idMenu: 13,
      descripcion: "Consultas",
      texto: "Consultas",
      idPadre: 0,
      icono: "fas fa-shopping-cart",
      url: "",
      visible: true,
      activo: true,
      subItems: true,
      idModulo: 1003,
      hijos: [
        {
          idMenu: 18,
          idPadre: 13,
          texto: "Estado de Cuenta",
          descripcion: "Registro Pago",
          icono: "fas fa-store",
          url: "afiliados/estado-cuenta",
          visible: true,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 19,
          idPadre: 13,
          texto: "Listado Pagos",
          descripcion: "Listado Pagos",
          icono: "fas fa-credit-card",
          url: "afiliados/posteo-pagos",
          visible: true,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 20,
          idPadre: 13,
          texto: "Expediente",
          descripcion: "Expediente",
          icono: "fas fa-id-card-alt",
          url: "afiliados/expediente",
          visible: true,
          activo: true,
          subItems: true,
        },
        {
          idMenu: 21,
          idPadre: 13,
          texto: "Programas",
          descripcion: "Programas",
          icono: "fas fa-book-open",
          url: "afiliados/programas",
          visible: true,
          activo: true,
          subItems: true,
        },

       
      ],
    },
  ];

  private Menus$ = new BehaviorSubject<menuInterface[]>([]);
  public responseMenus$: Observable<menuInterface[]> = this.Menus$.asObservable();

  constructor() { }

  consumirMenus() {
    this.Menus$.next(this.menus)
    // let menus: any[] = [];
    // menus = JSON.parse(localStorage.getItem('menus'));
    // this.Menus$.next(menus);
  }

  setearMenusFiltrado(item) {
    this.Menus$.next(item);
    localStorage.setItem("menus", JSON.stringify(item));
  }
}
