// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const conf =require('../../config.json');
import * as conf from '../../config.json';
const empresa:string="dev";

export const environment = {  
  production: false,
  apiUrl: conf["dev"]['apiUrl'] + '/',
  apiFile: conf["files"]['apiUrl'] + '/',
  site: "health-be",
  processEnv: 'dev',
  apiUrlSecurity: conf["dev"]['apiUrl']+'/',
  tokenLocal: "token_go504",
  nombreEmpresa: "viHts"

};






/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
