import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataApi } from 'src/app/Model/dataApi';
import { DataApiService } from 'src/app/services/data-api.service';
import { ToastrServiceLocal } from 'src/app/services/toast/toastr.service';
import { MensajesHttpService } from 'src/app/utils/mensajesHttp/mensajes-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PinSeguridadFacadeService {

  constructor(private _mensajesHttp: MensajesHttpService,
    private _toast: ToastrServiceLocal, private _dataApi: DataApiService) { }

  ngOnDestroy(): void {
    this.suscripciones.unsubscribe();
  }

  public suscripciones: Subscription = new Subscription();

  private Action$ = new BehaviorSubject<any>({});
  public responseAction$: Observable<any> = this.Action$.asObservable();

  private Cargando$ = new BehaviorSubject<boolean>(false);
  public responseCargando$: Observable<boolean> = this.Cargando$.asObservable();

  nuevoPin(params?: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PutDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/admin/pin`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (result.hasError === false) {
          this.Action$.next(result);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error en la asignación de su pin de seguridad', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }

  cambiarPin(params?: any) {
    this.Cargando$.next(true);
    this._toast.mensajeLoading('Cargando', 'Procesando la información');

    const request$ = this._dataApi.PostDataApi(`${environment.site}/api/sec/${environment.processEnv}/v1/admin/pin`, params).pipe(
      tap((result: DataApi | any) => {
        this._toast.clearToasts();
        if (result.hasError === false) {
          this.Action$.next(result);
        }
        this.Cargando$.next(false);
      }),
      catchError((error: HttpErrorResponse) => {
        this._mensajesHttp.mostrarErrorHttp(error, 'Ocurrio un error al cambiar su pin de seguridad', '');
        this.Cargando$.next(false);
        return EMPTY;
      })
    );
    return request$.subscribe();
  }
}
