import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import * as sha1 from 'sha1';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public formLogin: FormGroup;
  public hide = true;

  constructor(private toastr: ToastrService, public authService:AuthService) {
    this.formLogin = new FormGroup({
      usuario: new FormControl('', [Validators.required]),
      clave: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }


  showToaster(){
    this.toastr.success("Hello, I'm the toastr message.")
  }

  login() {
    if (this.formLogin.invalid) {
      this.formLogin.markAllAsTouched();
      return;
    }    
  
    this.authService.Login({
      clave: sha1(this.formLogin.value.clave),
      usuario: this.formLogin.value.usuario,
      medioDeAcceso: 'web'
    });
  }

  

}
