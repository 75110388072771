<div class="container-fluid lgCard  mx-auto">
    <div class="card card0 border-0">
        <div class="row">
            <div class="col-lg-6">
                <div class="card1 pb-5">
                    <div class="row">
                        <img src="../../../assets/images/Go504.png" class="logo">
                    </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
                        <img src="../../../assets/images/health.jpeg" class="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5">
                    <div class="text-center">
                        <h1>Bienvenido</h1>
                    </div>
                    <div class="row px-3 mb-4">
                        <div class="line"></div> <small class="or text-center">Or</small>
                        <div class="line"></div>
                    </div>
                    <form [formGroup]="formLogin" (ngSubmit)="login()">
                        <mat-form-field appearance="outline" style="margin-top: 5%;">
                            <mat-label>Correo</mat-label>
                            <input matInput placeholder="Correo" formControlName="usuario" required autocomplete="off">
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="margin-top: 5%;">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña"
                                formControlName="clave" required autocomplete="off">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>

                        <div class="text-center mt-4" *ngIf="!(authService.responseCargando$ | async)">
                            <button type="submit" class="bg-green text-center btnLogin"
                                mat-raised-button>Entrar</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div class="bg-green py-4 text-center">
            <div class="row px-3"> <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; 2021. GO504 Todos los derechos
                    reservados</small>
                <div class="social-contact ml-4 ml-sm-auto"> <span class="fa fa-facebook mr-4 text-sm"></span> <span
                        class="fa fa-google-plus mr-4 text-sm"></span> <span class="fa fa-linkedin mr-4 text-sm"></span>
                    <span class="fa fa-twitter mr-4 mr-sm-5 text-sm"></span>
                </div>
            </div>
        </div>
    </div>
</div>