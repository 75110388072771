import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { CambioPasswordComponent } from './layout/cambio-password/cambio-password.component';
import { FullComponent } from './layout/full.component';
import { MosaicMenuComponent } from './layout/mosaic-menu/mosaic-menu.component';
import { PinSeguridadComponent } from './layout/pin-seguridad/pin-seguridad.component';
import { LoginComponent } from './login/login/login.component';
import { ProfileComponent } from './layout/profile/profile.component';
import { RolGuard } from './guards/rol.guard';

const routerOptions: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate:[RolGuard]
        
      },
      {
        path: 'seguridad',
        loadChildren: () => import('./modules/seguridad/seguridad.module').then(m => m.SeguridadModule)
      },
      { path: 'report',
        loadChildren: () => import('./modules/reportes/reportes.module').then(m => m.ReportesModule)
      },
      {
        path: 'doctor',
        loadChildren: () => import('./modules/doctor/doctor.module').then(m => m.DoctorModule)
      }

    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'mosaicMenu',
    component: MosaicMenuComponent
  },
  {
    path: 'cambioPassword/:idTipoAccion',
    component: CambioPasswordComponent
  },
  {
    path: 'pinSeguridad/:idTipoAccion',
    component: PinSeguridadComponent
  },
  {
    path: 'perfil',
    component: ProfileComponent
  },
  {
    path: 'changePass',
    component: CambioPasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
