import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit} from '@angular/core';
import { AppNavbarComponent } from '../shared/app-navbar/app-navbar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { environment } from 'src/environments/environment';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.style.css']
})
export class FullComponent implements OnDestroy, AfterViewInit {
  public mobileQuery: MediaQueryList;
  public opened:boolean = true;
  public nombreEmpresa = environment.nombreEmpresa;
  
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    // this.elemento.ontoggle("toggled");
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {
  }
}
