import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolGuard implements CanActivate {
  PIN:String
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      
      this.PIN=localStorage.getItem('rol');
      if (this.PIN=='8C7FDBBE-DY4E-40L0-83A1-8AUI56DE9522') {
        return true
      } else {
        return false
      }
  }
  
}
